import { NO_CONNECTION, TOAST } from "Redux/ActionTypes";

const initialState = {
  internetConnected: true,
  lastAction: null,
  globalPupUp: {},
  toast_mesage: {},
  languageList: [],
  genderList: []
};

const common = (state = initialState, action) => {
  switch (action.type) {
    case NO_CONNECTION.UPDATE_CONNECTION:
      return {
        ...state,
        internetConnected: action.value,
        lastAction: action.lastAction
      };
    case NO_CONNECTION.SESSION_EXPIRE:
      return {
        ...state,
        globalPupUp: action.payload
      };
    case TOAST.TOAST_ERROR:
      console.log("333", action.payload);
      return {
        ...state,
        toast_mesage: {
          message: action.payload,
          error: true
        }
      };
    case TOAST.TOAST_SUCCESS:
      return {
        ...state,
        toast_mesage: {
          message: action.payload,
          error: false
        }
      };
    case TOAST.TOAST_REMOVE:
      return {
        ...state,
        toast_mesage: {}
      };

    default:
      return { ...state };
  }
};

export default common;
