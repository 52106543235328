import { SURVEY, USER } from "../ActionTypes";

const initialState = {
  survey_info: null,
  survey_responses: [],
  patient_id: null,
  risk_result: null,
  user_details: false
};

const Survey = (state = initialState, action) => {
  switch (action.type) {
    case SURVEY.SURVEY_INFO:
      return {
        ...state,
        survey_info: action.payload
      };
    case SURVEY.SURVEY_RESPONSE:
      return {
        ...state,
        survey_responses: [...state.survey_responses, action.payload]
      };
    case SURVEY.SURVEY_BACK: {
      return {
        ...state,
        survey_responses: [...action.payload]
      };
    }
    case SURVEY.SURVEY_PUT: {
      return {
        ...state,
        patient_id: action.payload
      };
    }
    case USER.USER_RESULT:
      return {
        ...state,
        risk_result: action.payload
      };
    case USER.USER_DETAILS:
      console.log("8888", action.payload);
      return {
        ...state,
        user_details: action.payload
      };

    default:
      return {
        ...state
      };
  }
};

export default Survey;
