import React, { Component } from "react";
import { connect } from "react-redux";
import { toast } from "Constants/helper";

class Toastr extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentWillReceiveProps = nextProps => {};

  closeToast = () => {
    const { dispatch } = this.props;
    dispatch(toast.remove());
  };

  render() {
    const { toaster } = this.props;
    return (
      <div
        className={`snackBar ${toaster.error ? "errorSnk" : "successSnk"} ${
          toaster.message ? "" : "d-none"
        }`}
        style={{ zIndex: "9999" }}
      >
        {" "}
        {/* add 'errorSnk' class for errorShow */}
        <div className="snkIco">
          {/* <img src={info_green} /> */}
          <p>{toaster.message}</p>
        </div>
        <button className="snkClsBtn ripple" onClick={this.closeToast}>
          {/* <img src={closeWht} /> */}
        </button>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    toaster: state.common.toast_mesage
  };
};

export default connect(mapStateToProps)(Toastr);
