import Survey from "./Survey";
import loader from "./Loader";
import { User } from "./User";
import { combineReducers } from "redux";
import common from "./common";

export default combineReducers({
  Survey,
  loader,
  User,
  common
});
