import React from "react";
import { Provider } from "react-redux";
import "./App.css";

import AppRouter from "./AppRouter";
import LoaderComponent from "./Components/Common/LoaderComponent";
import Toaster from "./Components/Common/Toaster";

import store from "./store";

function App() {
  return (
    <Provider store={store} key="provider">
      <LoaderComponent />
      <Toaster />

      <div className="App">
        {/* <HomePage /> */}
        {/* {<DoctorList />} */}
        {/* <ResultComponent /> */}

        {<AppRouter />}
      </div>
    </Provider>
  );
}

export default App;
