import React from "react";
import { Route, withRouter } from "react-router-dom";

class RouteLayout extends React.Component {
  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    const { path, Component, Layout, detail, ...rest } = this.props;
    if (Layout) {
      return (
        <Layout detail={detail}>
          <Route path={path} component={Component} {...rest} />
        </Layout>
      );
    } else {
      return <Route path={path} component={Component} {...rest} />;
    }
  }
}

export default withRouter(RouteLayout);
