import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import immutableCheckMiddleWare from "redux-immutable-state-invariant";
import { createLogger } from "redux-logger";
import Reducers from "./Redux/Reducers";

const middleWare = [];
middleWare.push(thunk);

if (process.env.NODE_ENV === "development") {
  middleWare.push(immutableCheckMiddleWare());
}

const loggerMiddleWare = createLogger({
  predicate: () => process.env.NODE_ENV === "development"
});

middleWare.push(loggerMiddleWare);
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  Reducers,
  {},
  composeEnhancers(applyMiddleware(...middleWare))
);

export default store;
