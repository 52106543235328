import { LOADER, TOAST } from "Redux/ActionTypes";
import { SURVEY } from "../Redux/ActionTypes";
import birdgender from "assets/images/bird-age.png";
import birdStick from "assets/images/bird-stick.png";
import birdExercise from "assets/images/bird-excercise.png";
import birdWaist from "assets/images/bird-waist.png";
import family from "assets/images/family.svg";
import exercise from "assets/images/exercise.svg";
import maleWaist from "assets/images/male-waist.png";
import femaleWaist from "assets/images/female-waist.png";
import checkhand from "assets/images/check-hands.svg";

export const domain = process.env.REACT_APP_API_URL;

export const sideImg = [
  {
    bird: birdgender,
    side: checkhand,
  },
  {
    bird: birdWaist,
    side: femaleWaist,
    type: "female",
  },
  {
    bird: birdWaist,
    side: maleWaist,
    type: "male",
  },
  {
    bird: birdExercise,
    side: exercise,
  },
  {
    bird: birdStick,
    side: family,
  },
];

export const getCurrentLanguge = () => {
  let generic = JSON.parse(localStorage.getItem("generic")) || {};
  if (!generic.language) {
    generic.language = "EN";
  }
  return generic.language;
};

export const showLoader = () => {
  return (dispatch) => {
    dispatch({
      type: LOADER.SHOW_LOADER,
    });
  };
};

export const hideLoader = () => {
  return (dispatch) => {
    dispatch({
      type: LOADER.HIDE_LOADER,
    });
  };
};

export const putResponses = (obj) => {
  return (dispatch) => {
    dispatch({
      type: SURVEY.SURVEY_RESPONSE,
      payload: obj,
    });
  };
};

export const regex = {
  // email: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i ,
  email: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.(in|com|net|org|cc|info|name|biz|tv|us|ws|mobi|de|am|fm|me|ca|bz|es|asia|co|se|xxx|la|buzz)$/i,
  password: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
  onlyDigit: /^[0-9]*$/,
  onlyDigitwith: /^[0-9/]*$/,
  onlyDigitdot: /^[0-9.]*$/,
  mobileNum: /^[6789]\d{9}$/,
  alphabetic: /[`0-9-!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/,
  pincode: /^(\d{6})$/,
  glucose: /^(0[5-9]|[5-9]|1[01234]|[5-9].[0-9]\d{0,1}|1[01234].[0-9]\d{0,1})$/,
  age: /^\d{1,2}$/,
  diagoned: /[\d]{2}\/[\d]{4}/,
};

export const toast = {
  error: (msg) => {
    console.log("2222", msg);
    return (dispatch) => {
      setTimeout(function() {
        dispatch(toast.remove());
      }, 6000);
      dispatch({ type: TOAST.TOAST_ERROR, payload: msg });
    };
  },
  success: (msg) => {
    return (dispatch) => {
      setTimeout(function() {
        dispatch(toast.remove());
      }, 7000);
      dispatch({ type: TOAST.TOAST_SUCCESS, payload: msg });
    };
  },
  remove: (msg) => {
    return (dispatch) => {
      dispatch({ type: TOAST.TOAST_REMOVE });
    };
  },
};
