import { USER } from "../ActionTypes";
const initialState = {
  doctor_list: {}
};

export const User = (state = initialState, action) => {
  switch (action.type) {
    case USER.DOCTOR_LIST: {
      return {
        ...state,
        doctor_list: { ...action.payload }
      };
    }
    default: {
      return { ...state };
    }
  }
};
