import React, { Component, lazy, Suspense } from "react";
import { Router, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";
import RouteLayout from "./hoc/RouteLayout";

// const baseUrl = process.env.REACT_APP_WEB_URL;
//export const history= createBrowserHistory({basename:baseUrl}); //for development deployment
export const history = createBrowserHistory(); // for production deployment
const HomePage = lazy(() => import("Components/Home/HomePage"));

const Survey = lazy(() => import("Components/Survey"));
const Footer = lazy(() => import("layout/default/footer"));
const DoctorList = lazy(() => import("Components/Doctors/DoctorList"));
// const FormComponent = lazy(() => import("Components/Survey/FormComponent"));
const ResultComponent = lazy(() => import("Components/Result/ResultComponent"));

class AppRouter extends Component {
  render() {
    return (
      <Suspense fallback={<div></div>}>
        <Router history={history}>
          <Switch>
            <RouteLayout exact path="/" component={HomePage} Layout={Footer} />
            <RouteLayout
              exact
              path="/survey/:id"
              component={Survey}
              Layout={Footer}
            />
            <RouteLayout
              exact
              path="/doctors"
              component={DoctorList}
              Layout={Footer}
            />
            <RouteLayout
              exact
              path="/share"
              component={Survey}
              Layout={Footer}
            />
            <RouteLayout
              exact
              path="/result"
              component={ResultComponent}
              Layout={Footer}
            />
          </Switch>
        </Router>
      </Suspense>
    );
  }
}

export default AppRouter;
